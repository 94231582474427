import '@/css/app.pcss';

import Glide from '@glidejs/glide';

// For the on Doc ready: Define a convenience method and use it
let ready = (callback) => {
  if (document.readyState != "loading") callback();
  else document.addEventListener("DOMContentLoaded", callback);
}


ready(() => { 
  /* Do things after DOM has fully loaded */ 
	
	//---------------------------------------------------------------  Hide the hero scroll hint after x amount of seconds
	
	let heroScrollHint = document.getElementById("hero-scroll-hint");
	
	if( heroScrollHint ){
		
		setTimeout(function () {
			var element = document.getElementById("hero-scroll-hint");
			element.classList.add("hero-scroll-hint-hidden");
		}, 5000);
		
	} 
	
	//--------------------------------------------------------------- Navigation

	
	// Toggle menu for mobile with hamburger
	// Toggle class 'mega-menu-visible-mobile'
	
	document.querySelectorAll('.mobile-menu-trigger').forEach(item => {
	
		item.addEventListener('click', event => {
			
			event.preventDefault();
			event.stopPropagation();
			
			// close sub menus on the menus so we can reset them
			
			closeAllSubMenus();
			
			// toggle the open / closed state on the mega menu
			let targetControl = item.getAttribute('aria-controls');
			
			//console.log(targetControl);
			
			let targetMenu = document.getElementById(targetControl);
			
			let siteHeader = document.getElementById('site-header');
			
			siteHeader.classList.toggle("site-header-mobile-menu-active");
			
			//console.log(targetMenu);
			
			toggleMenu(targetMenu);
			
			// toggle the open / closed state on the hamburger
			item.classList.toggle("is-active");
			
		})
	
	})
	
	
	
	function toggleMenu(targetMenubyID) {
			
		targetMenubyID.classList.toggle("mega-menu-visible-mobile");
		
		
	}
	
	function closeAllSubMenus() {
		
		document.querySelectorAll('.menu-item-has-children').forEach(item => {
			item.classList.remove("active-mega-menu-mobile");
		})
		
	}
		
	// Toggle mega menu sections for mobile
	// Toggle class 'active-mega-menu-mobile'
	
	document.querySelectorAll('.menu-item-has-children .child-item-toggle').forEach(item => {
	
		item.addEventListener('click', event => {
			
			event.preventDefault();
			event.stopPropagation();
			
			// toggle the open / closed state on the parent
			item.parentNode.classList.toggle("active-mega-menu-mobile");
			
			//console.log(item.parentNode);
			
		})
	
	})
	
	//--------------------------------------------------------------- Accordions
	
	
	  
	document.querySelectorAll('.accordion-content-toggle').forEach(item => {
	
		item.addEventListener('click', event => {
			
			event.preventDefault();
			event.stopPropagation();
			  
			let targetLink = item.getAttribute('data-toggle');
			
			let targetSection = document.getElementById(targetLink);
			
			toggleAccordian(targetSection);
			
			item.classList.toggle("accordion-content-toggle-expanded");
			
			
		})
	
	})
	
	
	
	function toggleAccordian(targetSection) {
			
		targetSection.classList.toggle("accordion-content-visible");
		
		
	}
	
	
  //--------------------------------------------------------------- Carousels
  
  
  

  // Gallery Carousel
	
	// Check to see if any elements with this class name exist
	
	let galleryCarousels = document.getElementsByClassName("gallery-carousel");
	
	if (galleryCarousels.length) {
	
		new Glide('.gallery-carousel',
		
		{
			type: 'carousel',
			startAt: 0,
			perView: 2,
			gap: 15,
			peek: 290,
			breakpoints: {
				1680: {
					perView: 2
				},
				1560: {
					perView: 2
				},
				1280: {
					perView: 2
				},
				1024: {
					perView: 2,
					peek: 50
				},
				768: {
					perView: 1,
					peek: 30
				}
			}
		
		}).mount();

	} 
	
	//--------------------------------------------------------------- Modals
	
	// Open modals
	  document.querySelectorAll('.modal-trigger').forEach(item => {
		
		item.addEventListener('click', event = thisEvent => {
		  
		  let targetLink = item.getAttribute('data-modal');
		  
		  let targetModal = document.getElementById(targetLink);
		 
		  openModal(targetModal);
		  
		  thisEvent.preventDefault();
		  
		})
		
	  })
	  
	  
	  function openModal(targetModal) {
		
		targetModal.classList.add("modal-visible");
		
	  }
	  
	  // Close modals
	  
	  document.querySelectorAll('.modal-close-trigger').forEach(item => {
		
		item.addEventListener('click', event = thisEvent => {
		  
		  let targetModals = document.querySelectorAll('.modal');
		 
		  targetModals.forEach(function(el) {
			el.classList.remove("modal-visible");
		  });
		  
		  thisEvent.preventDefault();
		  
		})
		
	  })
	
	//--------------------------------------------------------------- Alert Box
	
	let alertBox = document.getElementsByClassName("alert-box-small");
	
	if (alertBox.length) {
		
		// attach an event listener to the close button
		document.querySelectorAll('.close-alert-box').forEach(item => {
			
			item.addEventListener('click', event = thisEvent => {
			 
			 let targetAlertBox = document.querySelectorAll('.alert-box-small');
			 
			  targetAlertBox.forEach(function(el) {
				el.classList.add("alert-box-hidden");
			  });
			  
			  thisEvent.preventDefault();
			  
			})
			
		  })
		  
		
	} // end test for alertBox length
	
	
	let alertBoxOncePerSession = document.getElementsByClassName("alert-box-once-per-session"); 
	
	if (alertBoxOncePerSession.length) {
		
		let alertFadeDelay = 2000;
		
		if (!sessionStorage.viewed){
			setTimeout(function () {
				//$(modal).fadeIn( 500 );
				let targetAlertBoxToFade = document.querySelectorAll('.alert-box-small');
				
				targetAlertBoxToFade.forEach(function(el) {
					el.classList.add("alert-box-small-visible");
				});
				  
				sessionStorage.viewed=1
			}, alertFadeDelay);
		};
		
	}
	
	//--------------------------------------------------------------- quick-links-menu
	
	let quickLinkMenuItems = document.querySelectorAll('.quick-links-menu li a');
	
	quickLinkMenuItems.forEach(item => {
		
		item.addEventListener('click', event = thisEvent => {
		
		// remove active class from all quick links
		resetQuickLinks();
		// add the active class to this item
		item.classList.add('active-quick-link');
		
		// lets expand the target section if it has an accordion
		let targetAccordionSection = item.getAttribute('data-target-section');
		let targetAccordionSectionID = document.getElementById(targetAccordionSection);
		
		if (targetAccordionSectionID != null) {
			expandTargetAccordion(targetAccordionSectionID);
		}
		
		// close the quicklinks menu
		closeQuickLinksMenus();
	  
		})
		
	  })
	  
	  function resetQuickLinks(){
		  quickLinkMenuItems.forEach(item => {
			item.classList.remove('active-quick-link'); 
		  })
	  }
	  
	  function expandTargetAccordion(target){
		// lets find the content and add the required classes
		
		// the accordion-content
		let childAccordionContent = target.querySelectorAll('.accordion-content');
		
		childAccordionContent.forEach(function(el) {
			el.classList.add("accordion-content-visible");
		});
		
		// the accordion-content-toggle
		let childAccordionContentToggle = target.querySelectorAll('.accordion-content-toggle');
		
		childAccordionContentToggle.forEach(function(el) {
			el.classList.add("accordion-content-toggle-expanded");
		});
		
		
	  }
	  
	  function closeQuickLinksMenus() {
		// close the menu
		let quickLinksMenus = document.querySelectorAll('.quick-links-menu');
		
		quickLinksMenus.forEach(function(el) {
		el.classList.remove("accordion-content-visible");
		});
		
		let quickLinksMenuHeaders = document.querySelectorAll('.aside-sidebar-menu-header-mobile');
		
		quickLinksMenuHeaders.forEach(function(el) {
		el.classList.remove("accordion-content-toggle-expanded");
		});
	  }
	 
	//--------------------------------------------------------------- Touch and 
	
	document.querySelectorAll('figure.table').forEach(element => {
		
		element.addEventListener("scroll", (event) => {
		  if (element.scrollLeft < 1){
			  /* element scrolled far left lets add the scroll hint */
			  element.classList.remove("table-shadow-hidden");
		  } else {
			  /* element scrolling initiated lets hide the scroll hint */
			  element.classList.add("table-shadow-hidden");
		  }
		});
		
	})
		
		
	//--------------------------------------------------------------- Highlight quick link links as sections come into view not working as sections are too short
	
	
	const copySections = document.querySelectorAll('.copy-section');
	
	const observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			const id = entry.target.getAttribute('id');
			if (entry.intersectionRatio > 0) {
				// console.log(id + ' has entered the building');
			} else {
				// console.log(id + ' has left the building');
				// lets remove the class 'active-quick-link' from the corresponding menu item
				document.querySelectorAll(`.quick-links-menu li a[href="#${id}"]`).forEach(quicklink => {
					quicklink.classList.remove('active-quick-link');
				})
				
				//document.querySelector(`#quick-links-menu-2 li a[href="#${id}"]`).classList.remove('active-quick-link');
			}
		});
	});
	
	// Track all sections that have an `id` applied
	copySections.forEach(section => {
		observer.observe(section);
	});
	  
	 
	  
	//--------------------------------------------------------------- Donor Widget
	
	let donorWidget = document.getElementsByClassName("donor-widget");
	
	
	if (donorWidget.length) {
		
		let donorButtonPrevTarget;
		let donorTargetPrevSlide;
		let donorButtonNextTarget;
		let donorTargetNextSlide;
		let donorScrollAnchor = document.getElementById('donor-widget');
		let focusDonorButton;
		let dataProgress = 1;
		let dataProgressBar = document.querySelector('#donor-widget-progress-bar');
		let dataProgressCounter = document.querySelector('#donor-widget-progress-counter');
		let donorBackButton = document.querySelector('#donor-widget-back-button');
		let donorContinueButton = document.querySelector('#donor-widget-continue-button');
		let donorWidgetSlideButtons = document.querySelectorAll('.donor-widget-slide-button');
		let donorWidgetSlides = document.querySelectorAll('.donor-widget-slide');
		
		function scrollToDonorWidgetTop(){
			
			console.log(donorScrollAnchor);
			donorScrollAnchor.scrollIntoView();
			
		}
		
		function updateDonorProgress(){
			
			if ( dataProgress == 1){
				dataProgressBar.classList.remove("step-2", "step-3");
				dataProgressCounter.textContent="1";
			} else if ( dataProgress == 2){
				dataProgressBar.classList.remove("step-3");
				dataProgressBar.classList.add("step-2");
				dataProgressCounter.textContent="2";
			} else if ( dataProgress == 3){
				dataProgressBar.classList.remove("step-2");
				dataProgressBar.classList.add("step-3");
				dataProgressCounter.textContent="3";
			}
			
		}
		
		function loadPrevSlide(){
			
			scrollToDonorWidgetTop();
			
			clearSelectedDonorButtons();
			
			donorContinueButton.classList.remove("hide-donor-continue-button");
			
			donorTargetPrevSlide.classList.add("donor-widget-slide-visible");
			
			dataProgress = donorTargetPrevSlide.getAttribute('data-slide-progress'); 
			updateDonorProgress();
			
			if ( donorTargetPrevSlide.id == "donor_widget_slide_start"){
				// hide the back button
				donorBackButton.disabled = true;
			}
			
			
		}
		
		function loadTargetSlide(){
			
			scrollToDonorWidgetTop();
			
			if ( donorTargetNextSlide.classList.contains('donor-widget-end-slide') ){
				donorContinueButton.classList.add("hide-donor-continue-button");
			} else{
				donorContinueButton.classList.remove("hide-donor-continue-button");
			}
			
			dataProgress = donorTargetNextSlide.getAttribute('data-slide-progress'); 
			updateDonorProgress();
			
			donorTargetNextSlide.classList.add("donor-widget-slide-visible");
		}
		
		function hideSlides(){
			donorWidgetSlides.forEach(item => {
				
				item.classList.remove("donor-widget-slide-visible");
				
			})
		}
		
		function clearSelectedDonorButtons(){
			donorWidgetSlideButtons.forEach(item => {
				
				item.classList.remove("selected");
				
			})
		}
		
		donorBackButton.addEventListener('click', ()=>{
			// alert('continue clicked');
			// hide all slides
			hideSlides();
			// disable the continue button
			donorContinueButton.disabled = true;
			// show the target slide
			loadPrevSlide();
			// update donorTargetPrevSlide so we can navigate backwards again
			donorButtonPrevTarget = donorTargetPrevSlide.getAttribute('data-previous'); 
			donorTargetPrevSlide = document.getElementById(donorButtonPrevTarget);
		})
			
		donorContinueButton.addEventListener('click', ()=>{
			// alert('continue clicked');
			// hide all slides
			hideSlides();
			// disable the continue button
			donorContinueButton.disabled = true;
			//enable the back button
			donorBackButton.disabled = false;
			// show the target slide
			loadTargetSlide();
		})
			
		// attach event listeners to buttons with the class: donation-widget-slide-button
		donorWidgetSlideButtons.forEach(item => {
			
			item.addEventListener('click', event = thisEvent => {
				
				// remove selected state from all donorWidgetSlideButtons
				clearSelectedDonorButtons()
				
				// add selected state to this button
				focusDonorButton = item;
				focusDonorButton.classList.add("selected");
				
				donorButtonNextTarget = item.getAttribute('data-target'); 
				donorTargetNextSlide = document.getElementById(donorButtonNextTarget);
				
				// set the previous slide based on the target slides data-previous attribute
				donorButtonPrevTarget = donorTargetNextSlide.getAttribute('data-previous'); 
				donorTargetPrevSlide = document.getElementById(donorButtonPrevTarget);
				
				// disable the continue button so the user is forced to make a selection
				donorContinueButton.disabled = false;
			
			})
			
		})
		
		// attach event listener to slide navigation 'continue button'
		
	} // end test for donorWidget length
	
}); // END DOC READY
